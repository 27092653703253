import React from 'react';

//On importe les images
import Arbre from '../../images/olive-tree.gif';

const LoadingButton = ({ isLoading, disabled, className = '' }) => {
  return (
    <div className="flex items-center gap-4">
      <button
        type="submit"
        className={`${className} bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed min-w-[120px]`}
        disabled={disabled || isLoading}
      >
        Enregistrer
      </button>
      
      {isLoading && (
        <img 
          src={Arbre}
          alt="Loading..." 
          className="h-8 w-8"
          style={{ height: '50px', width: '50px' }}
        />
      )}
    </div>
  );
};

export default LoadingButton;