// client/src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Dashboard from "./components/dashboard/dashboard";
import PrivateRoute from "./components/routing/privateRoute";
import GoogleMapsProvider from "./components/map/GoogleMapsProvider";
import ProfileForm from "./components/profile/profileForm";
import Contact from "./pages/contact";
import Admin from "./components/admin/admin"
import AdminRoute from "./components/routing/adminRoute.js"
import AccessCodeGenerator from "./components/admin/accessCodeGenerator";





function App() {
  return (
    <Router>
      <Routes>
        {/* Routes publiques */}
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact" element={<Contact />} />

        {/* Routes protégées */}
        <Route
          path="/bureau"
          element={
            <AdminRoute>
              <Admin />
            </AdminRoute>
          }
        />


        <Route
          path="/bureau/code"
          element={
            <AdminRoute>
              <AccessCodeGenerator />
            </AdminRoute>
          }
        />


        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <GoogleMapsProvider>
                <Dashboard />
              </GoogleMapsProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="/fiche"
          element={
            <PrivateRoute>
              <GoogleMapsProvider>
                <ProfileForm />
              </GoogleMapsProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="/fiche/:id"
          element={
            <PrivateRoute>
              <GoogleMapsProvider>
                <ProfileForm />
              </GoogleMapsProvider>
            </PrivateRoute>
          }
        />











      </Routes>

    </Router>
  );
}

export default App;
