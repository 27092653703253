// src/components/dashboard/dashboard.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { GoogleMap } from "@react-google-maps/api";
import ProfilePopup from "../profile/profilePopup";
import { Alert, AlertTitle, AlertDescription } from "../ui/alert";

//On importe les composants
import Header from '../header';
import Footer from '../footer'



function Dashboard() {
  const [profile, setProfile] = useState(null);
  const [username, setUsername] = useState(""); // Stocke le nom d'utilisateur connecté
  const [allProfiles, setAllProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null); // État pour le profil sélectionné
  const [photoUrl, setPhotoUrl] = useState(null);
  const [adminError, setAdminError] = useState(false);


  const mapRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const error = localStorage.getItem("adminError");

    if (error) {
      setAdminError(error);
      localStorage.removeItem("adminError");
    }
  }, []);




  useEffect(() => {
    const checkAuth = async () => {
      try {
        await axios.get('/api/check-auth');
      } catch (error) {
        // Si l'authentification échoue, redirige vers la page de login
        navigate('/login');
      }
    };

    checkAuth();
  }, [navigate]);



  useEffect(() => {
    const fetchAllProfiles = async () => {
      try {
        const token = localStorage.getItem("token");
        const res = await axios.get("/profiles", {
          headers: { "x-auth-token": token },
        });
        setAllProfiles(res.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de toutes les fiches",
          error
        );
      }
    };
    fetchAllProfiles();
  }, []);


  //Récupérer le profil de l'utilisateur connecté
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return;
        }

        const res = await axios.get("/profiles/me", {
          headers: { "x-auth-token": token },
        });

        // La réponse contient maintenant un champ exists
        if (res.data.exists) {
          setProfile(res.data);
          if (res.data.user?.username) {
            setUsername(res.data.user.username);
          }
        } else {
          // Profil non existant, on met à null mais on peut récupérer le username
          setProfile(null);
          if (res.data.username) {
            setUsername(res.data.username);
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du profil", error);
        // Ici on ne gère plus le 404 car ce n'est plus un cas d'erreur
        // On ne traite que les vraies erreurs (500, etc.)
      }
    };
    fetchProfile();
  }, []);


  // Nouvelle utilisation pour charger les marqueurs sur la carte
  useEffect(() => {
    const loadMarkers = async () => {
      if (mapRef.current && window.google && allProfiles.length > 0) {
        const { AdvancedMarkerElement } =
          await window.google.maps.importLibrary("marker");

        allProfiles.forEach((profile) => {
          if (profile.location && profile.location.coordinates) {
            const marker = new AdvancedMarkerElement({
              position: {
                lat: profile.location.coordinates[1],
                lng: profile.location.coordinates[0],
              },
              map: mapRef.current,
              title: `Famille ${profile.familyName}`,
            });

            // Ajouter l'événement click sur le marqueur
            marker.addListener("click", () => {
              setSelectedProfile(profile);
            });
          }
        });
      }
    };

    loadMarkers();
  }, [mapRef, allProfiles]);

  const handleCreateOrEditProfile = () => {
    if (profile) {
      // Si un profil existe, rediriger vers la page d'édition
      navigate(`/fiche/${profile._id}`);
    } else {
      // Sinon, rediriger vers la page de création
      navigate('/fiche');
    }
  };


  const handleDeleteProfile = async () => {
    if (!window.confirm("Êtes-vous sûr de vouloir supprimer votre profil ?")) {
      return;
    }

    try {
      const token = localStorage.getItem("token");

      await axios.delete(`/profiles/${profile._id}`, {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
      });

      setProfile(null);
      alert("Profil supprimé avec succès");
    } catch (error) {

      if (error.response) {
        if (error.response.status === 403) {
          alert("Erreur d'autorisation. " + (error.response.data?.msg || ""));
        } else if (error.response.status === 404) {
          alert("Profil non trouvé");
          setProfile(null);
        } else {
          alert("Une erreur est survenue lors de la suppression du profil");
        }
      } else if (error.request) {
        alert(
          "Impossible de contacter le serveur. Veuillez réessayer plus tard."
        );
      } else {
        alert("Une erreur est survenue lors de la suppression du profil");
      }
      console.error("Erreur lors de la suppression du profil", error);
    }
  };


  // Supprimer son compte et toutes ses données
const handleDeleteAccount = async () => {
  if (!window.confirm("⚠️ ATTENTION : Cette action est irréversible. Votre compte et toutes vos données seront définitivement supprimés. Êtes-vous sûr de vouloir continuer ?")) {
    return;
  }

  try {
    const token = localStorage.getItem("token");

    // Si l'utilisateur a un profil avec une photo, on le supprime d'abord
    if (profile && profile._id) {
      try {
        await axios.delete(`/profiles/${profile._id}`, {
          headers: { "x-auth-token": token }
        });
      } catch (error) {
        console.error("Erreur lors de la suppression du profil:", error);
      }
    }

    console.log("Tentative de suppression du compte utilisateur...");
    
    // Suppression du compte utilisateur
    await axios.delete('/users/me', {
      headers: { "x-auth-token": token }
    });
    
    console.log("Compte utilisateur supprimé avec succès");

    // Nettoyage du localStorage et redirection
    localStorage.removeItem("token");
    navigate('/'); // Changé de '/login' à '/'
  } catch (error) {
    console.error("Erreur lors de la suppression du compte:", error);
    alert("Une erreur est survenue lors de la suppression du compte.");
  }
};



  useEffect(() => {
    if (profile && profile._id) {
      const fetchPhotoUrl = async () => {
        try {
          const token = localStorage.getItem('token');

          const res = await axios.get(`/profiles/${profile._id}/photo-url`, {
            headers: { 'x-auth-token': token },
          });

          setPhotoUrl(res.data.photoUrl);
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'URL de la photo', error);
        }
      };
      fetchPhotoUrl();
    }
  }, [profile]);







  return (
    <div className="container mx-auto p-4">

      <Header />

      {/* Ajouter cette condition pour afficher l'alerte d'erreur */}
      {adminError && (
        <Alert variant="destructive" className="mb-4">
          <AlertTitle>Erreur d'accès</AlertTitle>
          <AlertDescription>{adminError}</AlertDescription>
        </Alert>
      )}


      <h1 className="text-2xl font-semibold text-gray-800 mb-6">
        Bienvenue sur HippoHub
      </h1>

      {username && (
        <>
          <p className="text-gray-700 mb-4">
            Vous êtes connecté avec le compte : <strong>{username}</strong>
          </p>
          <button
            onClick={handleDeleteAccount}
            className="mb-6 px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors"
          >
            Supprimer mon compte et mes données
          </button>
        </>
      )}

      {!profile ? (
        <>
          <p>Vous n'avez pas encore créé de fiche.</p>
          <button
            onClick={handleCreateOrEditProfile}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Créer une fiche
          </button>
        </>
      ) : (
        <div className="bg-white p-6 rounded shadow-lg mb-6">
          <div className="flex flex-col md:flex-row gap-6">
            {/* Photo de profil */}
            {profile && (
              <div className="w-full md:w-1/3">
                {photoUrl ? (
                  <img
                    src={photoUrl}
                    alt={`Famille ${profile.familyName}`}
                    className="w-full h-64 object-cover rounded-lg shadow-md"
                    onError={(e) => {
                      console.error('Erreur de chargement de l\'image:', e);
                      // Optionnel : mettre une image par défaut
                      // e.target.src = '/path/to/default/image.png';
                    }}
                  />
                ) : (
                  <div className="w-full h-64 bg-gray-200 rounded-lg shadow-md flex items-center justify-center">
                    <span className="text-gray-500">Aucune photo disponible</span>
                  </div>
                )}
              </div>
            )}

            {/* Informations du profil */}
            <div className="w-full md:w-2/3">
              <h2 className="text-xl font-semibold mb-2">Votre Fiche</h2>
              <p>
                <strong>Nom de famille :</strong> {profile.familyName}
              </p>
              <p>
                <strong>Personne 1 :</strong> {profile.person1.name},{" "}
                {profile.person1.profession}, {profile.person1.phone}
              </p>
              <p>
                <strong>Personne 2 :</strong> {profile.person2.name},{" "}
                {profile.person2.profession}, {profile.person2.phone}
              </p>
              <p>
                <strong>Adresse :</strong> {profile.addressNumber}{" "}
                {profile.streetName} à Lambersart 59130
              </p>

              <div className="flex mt-4">
                <button
                  onClick={handleCreateOrEditProfile}
                  className="px-4 py-2 mr-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
                >
                  Éditer
                </button>
                <button
                  onClick={handleDeleteProfile}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-6 rounded shadow-lg">
        <h2 className="text-xl font-semibold mb-4">
          Localisation sur la carte
        </h2>
        <GoogleMap
          mapContainerStyle={{ height: "500px", width: "100%" }}
          center={{ lat: 50.644524, lng: 3.028651 }}
          zoom={14}
          options={{
            mapId: "58b2bff20e74b2e0", // Map ID valide
            mapTypeControl: true, // Active le contrôle des types de carte
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.DEFAULT, // Style par défaut (menu déroulant)
              position: window.google.maps.ControlPosition.TOP_RIGHT, // Position du contrôle
            },
            mapTypeId: "roadmap", // Type de carte par défaut : "roadmap" ou "satellite"
          }}
          onLoad={(map) => {
            mapRef.current = map;
          }}
        />
      </div>

      {/* Section d'affichage des détails sous la carte */}
      {selectedProfile && (
        <div className="bg-white p-6 rounded shadow-lg mt-6 MainFicheFamille">
          <div className="ContainerFicheFamille">
            {selectedProfile.photo ? (
              <img
                src={`${process.env.REACT_APP_API_URL
                  }/profiles/${selectedProfile.photo.split("\\").pop()}`}
                alt={`Famille ${selectedProfile.familyName}`}
                className="w-64 h-64 object-cover rounded shadow-md mb-4"
              />
            ) : (
              <div className="w-64 h-64 bg-gray-200 rounded shadow-md flex items-center justify-center">
                <span className="text-gray-500">Aucune photo</span>
              </div>
            )}
          </div>

          <div className="ContainerFicheFamille">
            <div className="ItemFicheFamille">
              <h2 className="text-xl font-semibold mb-4">
                Famille {selectedProfile.familyName}
              </h2>

              <p>
                <strong>Adresse :</strong> {selectedProfile.addressNumber}{" "}
                {selectedProfile.streetName} <br />
                59130 Lambersart
              </p>
            </div>

            <div className="ItemFicheFamille">
              <p><strong>Personne 1 :</strong> {selectedProfile.person1.name}</p>
              <p>Profession : {selectedProfile.person1.profession}</p>
              <p>Téléphone : <a
                href={`tel:${selectedProfile.person1.phone}`}
                className="text-blue-600 hover:text-blue-800 hover:underline"
              >
                {selectedProfile.person1.phone}
              </a></p>
            </div>

            <div className="ItemFicheFamille">
              <p><strong>Personne 2 :</strong> {selectedProfile.person2.name}</p>
              <p>Profession : {selectedProfile.person2.profession}</p>
              <p>Téléphone : <a
                href={`tel:${selectedProfile.person2.phone}`}
                className="text-blue-600 hover:text-blue-800 hover:underline"
              >
                {selectedProfile.person2.phone}
              </a></p>
            </div>
          </div>
        </div>
      )}

      {selectedProfile && (
        <ProfilePopup
          profile={selectedProfile}
          onClose={() => setSelectedProfile(null)}
        />
      )}

      <Footer />

    </div>
  );
}

export default Dashboard;
