import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import UsersTable from './usersTable';
import CopyEmailsButton from './copyEmailButton';


function Admin() {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4 py-8">
      <Header/>
      
      <div className='MainBureau'>
        <h1 className="text-3xl font-bold mb-6">Administration</h1>
        
        <div className="space-y-4">
          <button
            onClick={() => navigate('/bureau/code')}
            className="bg-orange-600 text-white px-6 py-3 rounded-md hover:bg-orange-700 transition-colors"
          >
            Générer des codes d'accès
          </button>

          <CopyEmailsButton />


          <p>&nbsp;</p>
          
          {/* Tableau des utilisateurs */}
          <UsersTable />
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default Admin;