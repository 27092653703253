// src/components/header.js

import React from 'react';
import { useNavigate } from 'react-router-dom';


//on importe les images
import Hippohub from "../images/logo-hippohub.webp";

function Header() {
  const navigate = useNavigate();

  //Gérer la déconnexion, appelle /logout dans server.js
  const handleLogout = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
            method: 'POST',
            credentials: 'include', // Inclure les cookies dans la requête
            headers: {
              'Content-Type': 'application/json',
            },
      });

      if (response.ok) {
        localStorage.removeItem("token"); // Nettoyage côté client
        navigate('/'); // Redirige vers la page d'accueil
      } else {
        console.error('Erreur lors de la déconnexion');
      }
    } catch (error) {
      console.error('Erreur réseau:', error);
    }
  };



  return (
    <div className="MainHeader">
      
      <div className="ContainerHeader">
        <a href="/contact">Contact</a>
      </div>
      <div className="ContainerHeader NoBackground">
        <a href="/dashboard"><img src={Hippohub} alt="logo hippohub" /></a>
      </div>
      <div className="ContainerHeader">
        <button
          onClick={handleLogout}
          className="ContainerHeader"
        >
          Déconnexion
        </button>
      </div>
    </div>
  );
}

export default Header;
