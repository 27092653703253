// src/utils/axios.js
import axios from 'axios';

const instance = axios.create({
  // URL de base avec fallback sur localhost si l'env n'est pas définie
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api',
  // Timeout pour éviter les requêtes infinies
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  },
  // Activer les credentials pour les cookies
  withCredentials: true
});

// Intercepteur pour les requêtes
instance.interceptors.request.use(
  (config) => {
    // Ajout du token d'authentification
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }

   

    return config;
  },
  (error) => {
    console.error('Erreur préparation requête:', {
      message: error.message,
      config: error.config
    });
    return Promise.reject(error);
  }
);

// Intercepteur pour les réponses
instance.interceptors.response.use(
  (response) => {
    // Log de debug en développement
    
    return response;
  },
  (error) => {
    // Construction d'un objet d'erreur détaillé
    const errorDetails = {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      url: error.config?.url,
      method: error.config?.method
    };

    // Gestion spécifique selon le type d'erreur
    if (error.code === 'ERR_NETWORK') {
      console.error('Erreur réseau :', errorDetails);
    } else if (error.response?.status === 401) {
      console.error('Erreur d\'authentification :', errorDetails);
      // Nettoyer le localStorage en cas d'erreur d'authentification
      localStorage.removeItem('token');
      // Rediriger vers la page de login si nécessaire
      window.location.href = '/login';
    } else if (error.response?.status === 403) {
      console.error('Erreur d\'autorisation :', errorDetails);
    } else {
      console.error('Erreur Axios :', errorDetails);
    }

    // Rejet de la promesse avec l'erreur originale
    return Promise.reject(error);
  }
);

// Export de l'instance configurée
export default instance;