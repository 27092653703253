import React, { useEffect, useState } from 'react';

//On importe les images
import Hippohub from "../images/logo-hippohub.webp";


export default function Contact() {
    const [isLoading, setIsLoading] = useState(true); // État pour gérer le chargement

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://tally.so/widgets/embed.js";
        script.async = true;
        script.onload = () => {
            // Initialiser Tally après le chargement du script
            window.Tally?.loadEmbeds();
            setIsLoading(false); // Une fois le script chargé, masquer le message de chargement
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
        <p>&nbsp;</p>

            <div className="MainContact flex justify-center items-center">
                <a href="/dashboard"><img src={Hippohub} alt="logo hippohub" /></a>
            </div>

            <div className='MainContact'>
                {isLoading && (
                    <p className="loading-message">
                        Le formulaire est en cours de chargement, veuillez patienter...
                    </p>
                )}
                <iframe
                    data-tally-src="https://tally.so/embed/wA6XXN?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                    loading="lazy"
                    width="100%"
                    height="200"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title="HippoHub"
                    style={{ display: isLoading ? 'none' : 'block' }} // Masquer l'iframe tant qu'elle est en cours de chargement
                ></iframe>
            </div>
        </>
    );
}
