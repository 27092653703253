// client/src/components/auth/login.js


import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import axios from '../../axios';
import { Eye, EyeOff } from "lucide-react";

///on importe les images
import Logo from '../../images/logo-hippohub.webp'

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      // D'abord, vérifier si l'utilisateur existe
      const checkUserResponse = await axios.get(`/users/check/${username}`);

      // Si l'utilisateur n'existe pas
      if (!checkUserResponse.data.exists) {
        setError("Cet identifiant n'existe pas");
        return;
      }

      // Si l'utilisateur existe, on tente la connexion
      try {
        const loginResponse = await axios.post('/users/login', { username, password });
        
        // On stocke uniquement le token et les informations non sensibles
        localStorage.setItem("token", loginResponse.data.token);
        localStorage.setItem("user", JSON.stringify({
          id: loginResponse.data.user.id,
          username: loginResponse.data.user.username
        }));
        
        navigate("/dashboard");
      } catch (loginError) {
        // Si le mot de passe est incorrect
        if (loginError.response && loginError.response.status === 401) {
          setError("Mot de passe incorrect");
        } else {
          setError("Une erreur est survenue lors de la connexion");
        }
      }
    } catch (error) {
      // Si l'erreur vient de la vérification de l'utilisateur
      if (error.response && error.response.status === 404) {
        setError("Cet identifiant n'existe pas");
      } else {
        setError("Une erreur est survenue lors de la connexion");
      }
      console.error("Erreur de connexion:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">

      <div className="LogoHead">
        <img src={Logo} alt="logo hippohub" />

      </div>

      <div className="w-full max-w-sm bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold text-center text-gray-700">
          Se connecter
        </h2>
        <form onSubmit={handleSubmit} className="mt-4">
          {error && (
            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-gray-600" htmlFor="username">
              Identifiant
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setError("");
              }}
              className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-600" htmlFor="password">
              Mot de passe
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
                className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 inset-y-0 flex items-center text-gray-600 hover:text-gray-800"
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5" />
                ) : (
                  <Eye className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="w-full py-2 mt-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Connexion
          </button>
        </form>
        <p className="mt-4 text-sm text-center text-gray-600">
          Pas encore de compte ?{" "}
          <Link to="/register" className="text-blue-500 hover:underline">
            Inscrivez-vous
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Login;