// client/src/components/routing/adminRoute.js

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "../../axios";

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    
    const checkAdmin = async () => {
      try {
        const token = localStorage.getItem("token");
        const userStr = localStorage.getItem("user");
        
        // Vérification plus stricte des données stockées
        if (!token || !userStr) {
          if (isMounted) {
            setIsAdmin(false);
            setError("Session expirée");
          }
          return;
        }

        let user;
        try {
          user = JSON.parse(userStr);
        } catch (e) {
          console.error("Erreur lors du parsing des données utilisateur");
          localStorage.removeItem("user"); // Nettoyage des données corrompues
          if (isMounted) {
            setIsAdmin(false);
            setError("Données de session invalides");
          }
          return;
        }

        if (!user.id) {
          console.warn("ID utilisateur manquant");
          if (isMounted) {
            setIsAdmin(false);
            setError("Données utilisateur incomplètes");
          }
          return;
        }

        const res = await axios.get("/admin/check", {
          headers: { 
            "x-auth-token": token,
            "Content-Type": "application/json"
          },
          params: { userId: user.id }
        });

        if (isMounted) {
          setIsAdmin(res.data?.isAdmin ?? false);
          setError(null);
        }

      } catch (error) {
        console.error("Erreur de vérification admin:", error);
        
        if (isMounted) {
          setIsAdmin(false);
          
          // Gestion spécifique des erreurs
          if (error.response) {
            switch (error.response.status) {
              case 401:
                setError("Session expirée");
                // Nettoyage du localStorage en cas de token invalide
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                break;
              case 403:
                setError("Accès non autorisé");
                break;
              default:
                setError("Erreur de vérification des droits");
            }
          } else if (error.request) {
            setError("Erreur de connexion au serveur");
          } else {
            setError("Une erreur inattendue est survenue");
          }
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    checkAdmin();

    // Cleanup function pour éviter les memory leaks
    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="spinner">Chargement...</div>
        </div>
      </div>
    );
  }

  if (!isAdmin) {
    // Si on a une erreur spécifique, on peut la passer en state à la page de redirection
    return <Navigate to="/dashboard" state={{ error: error }} replace />;
  }

  return children;
};

export default AdminRoute;