// src/components/maps/GoogleMapsProvider.js
import React from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places', 'marker'];

const googleMapsOptions = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries,
  version: 'weekly',  // On utilise la version weekly de manière consistante
  language: 'fr',     // Pour l'interface en français
  region: 'FR'        // Pour la région France
};

function GoogleMapsProvider({ children }) {
  const { isLoaded, loadError } = useLoadScript(googleMapsOptions);

  if (loadError) {
    console.error('Erreur de chargement Google Maps:', loadError);
    return (
      <div className="text-center p-4 text-red-600">
        Erreur de chargement de Google Maps. Veuillez réessayer plus tard.
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className="text-center p-4">
        Chargement de Google Maps...
      </div>
    );
  }

  return children;
}

export default GoogleMapsProvider;