// src/components/profile/profileForm.js

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom"
import axios from "../../axios";
import { GoogleMap } from "@react-google-maps/api";
import LoadingButton from './loadingButton';

//On importe les composants
import Header from '../header';
import Footer from '../footer'

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10 Mo en bytes


// Composant Modal personnalisé
const ErrorModal = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold text-red-600 mb-4">
          Erreur de fichier
        </h3>
        <p className="text-gray-700 mb-6">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

// Ajouter ceci après les imports
const defaultFormData = {
  familyName: "",
  person1: { name: "", profession: "", phone: "" },
  person2: { name: "", profession: "", phone: "" },
  addressNumber: "",
  streetName: "",
  city: "Lambersart",
  location: {
    type: "Point",
    coordinates: [3.027529, 50.646026], // [lng, lat]
  },
  photo: null,
};

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  mapId: "58b2bff20e74b2e0", // Gardez uniquement le mapId
};

const defaultCenter = {
  lat: 50.646026,
  lng: 3.027529,
};

function ProfileForm({ onSuccess }) {
  const { id } = useParams(); // Récupère l'ID du profil depuis l'URL
  const profileId = id || null;
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [signedPhotoUrl, setSignedPhotoUrl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isMapReady, setIsMapReady] = useState(false);
  const [formData, setFormData] = useState({
    familyName: "",
    person1: { name: "", profession: "", phone: "" },
    person2: { name: "", profession: "", phone: "" },
    addressNumber: "",
    streetName: "",
    location: {
      type: "Point",
      coordinates: [3.027529, 50.646026], // [lng, lat]
    },
    photo: null,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const [geocodingError, setGeocodingError] = useState("");
  const [loadingGeocoding, setLoadingGeocoding] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate(); // Hook pour la navigation

  // Ajouter cette fonction pour gérer la suppression de la photo
  const handleDeletePhoto = async () => {
    try {
      const token = localStorage.getItem("token");

      await axios.put(
        `/profiles/${profileId}/delete-photo`,
        {},
        {
          headers: { "x-auth-token": token },
        }
      );

      setPhotoUrl(null);
      setFormData((prev) => ({ ...prev, photo: null }));
    } catch (error) {
      console.error("Erreur lors de la suppression de la photo:", error);
      setError("Erreur lors de la suppression de la photo");
    }
  };


  // Fonction pour récupérer l'URL signée
  const fetchSignedPhotoUrl = async (profileId) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(`/profiles/${profileId}/photo-url`, {
        headers: { 'x-auth-token': token },
      });
      setSignedPhotoUrl(res.data.photoUrl);
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'URL de la photo', error);
      setSignedPhotoUrl(null);
    }
  };



  // Fonction pour afficher le modal d'erreur
  const showErrorModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const createMarker = useCallback(
    async (position) => {
      if (!mapRef.current || !window.google || !isMapReady) return;

      try {
        // Si un marqueur existe déjà, le supprimer proprement
        if (markerRef.current) {
          markerRef.current.setMap(null);
          markerRef.current = null;
        }

        // Créer le nouveau marqueur
        const { AdvancedMarkerElement } =
          await window.google.maps.importLibrary("marker");
        const marker = new AdvancedMarkerElement({
          map: mapRef.current,
          position,
          draggable: true,
        });

        marker.addListener("dragend", () => {
          const newPos = marker.position;
          const newPosition = {
            lat: newPos.lat,
            lng: newPos.lng,
          };
          setMarkerPosition(newPosition);
          setFormData((prev) => ({
            ...prev,
            location: {
              type: "Point",
              coordinates: [newPosition.lng, newPosition.lat],
            },
          }));
        });

        markerRef.current = marker;
      } catch (error) {
        console.error("Erreur lors de la création du marqueur:", error);
      }
    },
    [isMapReady]
  );

  // Handler pour les champs de formulaire simples
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  // Handler pour les champs imbriqués (person1 et person2)
  const handleNestedChange = useCallback((personKey, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [personKey]: {
        ...prevData[personKey],
        [field]: value,
      },
    }));
  }, []);

  // Fonction modifiée pour la validation du fichier
  const handlePhotoChange = useCallback((e) => {
    const file = e.target.files[0];

    if (file) {
      // Vérification de la taille
      if (file.size > MAX_FILE_SIZE) {
        e.target.value = ""; // Réinitialiser l'input file
        showErrorModal(
          "Le fichier est trop volumineux. La taille maximale autorisée est de 10Mo."
        );
        return;
      }

      // Vérification du type
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/webp",
      ];
      if (!allowedTypes.includes(file.type)) {
        e.target.value = ""; // Réinitialiser l'input file
        showErrorModal(
          "Format de fichier non autorisé. Utilisez JPG, JPEG, PNG ou WEBP."
        );
        return;
      }

      // Mettre à jour l'état avec le fichier sélectionné
      setFormData((prev) => ({
        ...prev,
        photo: file,
      }));
    }
  }, []);


  // Gestion du chargement initial de la carte
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setIsMapReady(true);
  }, []);

  // Mise à jour du marqueur quand la carte est prête ou la position change
  useEffect(() => {
    if (isMapReady && mapRef.current) {
      createMarker(markerPosition);
    }
  }, [isMapReady, markerPosition, createMarker]);

  // Chargement du profil
  useEffect(() => {
    if (profileId) {
      const fetchProfile = async () => {
        try {
          const token = localStorage.getItem("token");
          if (!token) {
            setError("Session expirée, veuillez vous reconnecter");
            return;
          }

          const res = await axios.get(`/profiles/${profileId}`);

          if (res.data) {
            // Mettre à jour les données du formulaire
            setFormData({
              familyName: res.data.familyName || "",
              person1: {
                name: res.data.person1?.name || "",
                profession: res.data.person1?.profession || "",
                phone: res.data.person1?.phone || "",
              },
              person2: {
                name: res.data.person2?.name || "",
                profession: res.data.person2?.profession || "",
                phone: res.data.person2?.phone || "",
              },
              addressNumber: res.data.addressNumber || "",
              streetName: res.data.streetName || "",
              city: res.data.city || "Lambersart",
              location: res.data.location || defaultFormData.location,
              photo: null, // Géré séparément
            });

            // Gérer la photo si elle existe
            if (res.data.photo) {
              setPhotoUrl(res.data.photo);
              await fetchSignedPhotoUrl(profileId);
            }

            // Mettre à jour la position sur la carte
            if (res.data.location?.coordinates) {
              setMarkerPosition({
                lat: res.data.location.coordinates[1],
                lng: res.data.location.coordinates[0],
              });
            }
          }
        } catch (error) {
          console.error("Erreur lors du chargement du profil:", error);
          setError(
            error.response?.data?.msg || "Erreur lors du chargement du profil"
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchProfile();
    } else {
      setIsLoading(false);
    }
  }, [profileId]);


  // Nettoyage à la destruction du composant
  useEffect(() => {
    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
        markerRef.current = null;
      }
    };
  }, []);

  const handleGeocodeAddress = async () => {
    setGeocodingError("");
    setLoadingGeocoding(true);

    const fullAddress = `${formData.addressNumber} ${formData.streetName}, ${formData.city}, France`;

    try {
      const geocoder = new window.google.maps.Geocoder();
      const result = await new Promise((resolve, reject) => {
        geocoder.geocode({ address: fullAddress }, (results, status) => {
          if (status === "OK") {
            const isInLambersart = results[0].address_components.some(
              (component) =>
                component.long_name.toLowerCase() === "lambersart" &&
                component.types.includes("locality")
            );

            if (!isInLambersart) {
              reject(new Error("L'adresse doit être située à Lambersart"));
              return;
            }

            resolve(results[0]);
          } else {
            reject(new Error("Géocodage impossible"));
          }
        });
      });

      const { lat, lng } = result.geometry.location;
      const newPosition = {
        lat: lat(),
        lng: lng(),
      };

      setMarkerPosition(newPosition);
      setFormData((prevData) => ({
        ...prevData,
        location: {
          type: "Point",
          coordinates: [newPosition.lng, newPosition.lat],
        },
      }));
    } catch (error) {
      setGeocodingError(
        error.message || "Impossible de géolocaliser cette adresse"
      );
    } finally {
      setLoadingGeocoding(false);
    }
  };



  const geocodeAddress = async (address) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          // Vérifier si l'adresse est à Lambersart
          const isInLambersart = results[0].address_components.some(
            (component) =>
              component.long_name.toLowerCase() === "lambersart" &&
              component.types.includes("locality")
          );

          if (!isInLambersart) {
            reject(new Error("L'adresse doit être située à Lambersart"));
            return;
          }

          const location = results[0].geometry.location;
          resolve({
            lat: location.lat(),
            lng: location.lng()
          });
        } else {
          reject(new Error("Géocodage impossible"));
        }
      });
    });
  };




  const onSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Réinitialiser les erreurs
    setIsSubmitting(true); // Activer l'état de chargement


    try {
      // Construire l'adresse complète
      const fullAddress = `${formData.addressNumber} ${formData.streetName}, ${formData.city}, France`;

      // Tenter de géocoder l'adresse
      const coordinates = await geocodeAddress(fullAddress);

      // Mettre à jour les coordonnées dans le formData
      const updatedLocation = {
        type: "Point",
        coordinates: [coordinates.lng, coordinates.lat]
      };

      // Créer le FormData avec les coordonnées mises à jour
      const formDataToSend = new FormData();
      formDataToSend.append("familyName", formData.familyName || "");
      formDataToSend.append("person1", JSON.stringify(formData.person1 || {}));
      formDataToSend.append("person2", JSON.stringify(formData.person2 || {}));
      formDataToSend.append("addressNumber", formData.addressNumber || "");
      formDataToSend.append("streetName", formData.streetName || "");
      formDataToSend.append("location", JSON.stringify(updatedLocation));
      if (formData.photo) {
        formDataToSend.append("photo", formData.photo);
      }

      const token = localStorage.getItem("token");
      if (profileId) {
        await axios.put(`/profiles/${profileId}`, formDataToSend, {
          headers: {
            "x-auth-token": token,
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        await axios.post("/profiles", formDataToSend, {
          headers: {
            "x-auth-token": token,
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (onSuccess) onSuccess();
      navigate("/dashboard");
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire", error);
      const errorMessage =
        error.message || // Pour les erreurs de géocodage
        error.response?.data?.error ||
        error.response?.data?.msg ||
        "Une erreur est survenue lors de la soumission du formulaire";
      setError(errorMessage);
    }
  };


  // Fonction pour gérer le retour
  const handleBack = () => {
    navigate(-1); // Retourne à la page précédente
  };

  return (
    <>

      <Header />



      {isLoading ? (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>


          {/* Modal d'erreur */}
          <ErrorModal
            isOpen={showModal}
            message={modalMessage}
            onClose={() => setShowModal(false)}
          />

          <form onSubmit={onSubmit} className="bg-white p-6 rounded shadow-lg MainForm">

            {/* Bouton Retour */}
            <div className="mb-4">
              <button
                type="button"
                onClick={handleBack}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
              >
                Retour
              </button>
            </div>


            <h2 className="text-lg font-semibold mb-4">
              {profileId ? "Modifier le profil" : "Créer un profil"}
            </h2>

            <div className="mb-4">
              <label className="block text-gray-700">Nom de la famille</label>
              <input
                type="text"
                name="familyName"
                value={formData.familyName}
                onChange={handleChange}
                className="w-full p-2 border rounded mt-2"
                required
              />
            </div>

            {/* Personne 1 */}
            <div className="mb-4">
              <label className="block text-gray-700">Personne 1</label>
              <input
                type="text"
                placeholder="Prénom et nom"
                value={formData.person1.name}
                onChange={(e) =>
                  handleNestedChange("person1", "name", e.target.value)
                }
                className="w-full p-2 border rounded mt-2"
              />
              <input
                type="text"
                placeholder="Profession"
                value={formData.person1.profession}
                onChange={(e) =>
                  handleNestedChange("person1", "profession", e.target.value)
                }
                className="w-full p-2 border rounded mt-2"
              />
              <input
                type="text"
                placeholder="Téléphone"
                value={formData.person1.phone}
                onChange={(e) =>
                  handleNestedChange("person1", "phone", e.target.value)
                }
                className="w-full p-2 border rounded mt-2"
              />
            </div>

            {/* Personne 2 */}
            <div className="mb-4">
              <label className="block text-gray-700">Personne 2</label>
              <input
                type="text"
                placeholder="Prénom et Nom"
                value={formData.person2.name}
                onChange={(e) =>
                  handleNestedChange("person2", "name", e.target.value)
                }
                className="w-full p-2 border rounded mt-2"
              />
              <input
                type="text"
                placeholder="Profession"
                value={formData.person2.profession}
                onChange={(e) =>
                  handleNestedChange("person2", "profession", e.target.value)
                }
                className="w-full p-2 border rounded mt-2"
              />
              <input
                type="text"
                placeholder="Téléphone"
                value={formData.person2.phone}
                onChange={(e) =>
                  handleNestedChange("person2", "phone", e.target.value)
                }
                className="w-full p-2 border rounded mt-2"
              />
            </div>

            {/* Adresse */}
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Adresse</label>
              <div className="flex flex-col sm:flex-row gap-4">
                <input
                  type="text"
                  name="addressNumber"
                  placeholder="Numéro"
                  value={formData.addressNumber}
                  onChange={handleChange}
                  className="w-full sm:w-1/4 p-2 border rounded mt-2"
                  required
                />
                <input
                  type="text"
                  name="streetName"
                  placeholder="Nom de la rue"
                  value={formData.streetName}
                  onChange={handleChange}
                  className="w-full sm:w-2/4 p-2 border rounded mt-2"
                  required
                />
                <input
                  type="text"
                  value={formData.city}
                  placeholder="Lambersart"
                  className="w-full sm:w-1/4 p-2 border rounded mt-2 bg-gray-100"
                  readOnly
                />
              </div>
            </div>


            {/* Géolocalisation et Carte */}
            <div className="mb-4">
              <div className="flex items-center gap-4 mb-2">
                <button
                  type="button"
                  onClick={handleGeocodeAddress}
                  className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                  disabled={loadingGeocoding || !isMapReady}
                >
                  {loadingGeocoding
                    ? "Géolocalisation..."
                    : "Géolocaliser l'adresse"}
                </button>
                <div className="text-sm text-gray-600">
                  GPS : {markerPosition.lat.toFixed(6)},{" "}
                  {markerPosition.lng.toFixed(6)}
                </div>
              </div>
              {geocodingError && (
                <p className="text-red-500 mt-2">{geocodingError}</p>
              )}
            </div>

            {/* Carte Google Maps */}
            <div className="mb-4 border rounded overflow-hidden">
              <GoogleMap
                mapContainerStyle={{ height: "400px", width: "100%" }}
                center={markerPosition}
                zoom={15}
                onLoad={onMapLoad}
                options={mapOptions}
              />
            </div>

            {/* Upload photo */}
            <div className="mb-4">
              <label className="block text-gray-700">Photo</label>
              <p className="text-sm text-gray-500 mb-2">
                Taille maximale : 10Mo. Formats acceptés : JPG, JPEG, PNG, WEBP
              </p>
              {/* Affichage de la photo existante */}
              {photoUrl ? (
                <div className="mb-4">
                  {signedPhotoUrl ? (
                    <img
                      src={signedPhotoUrl}
                      alt="Membres de la famille"
                      className="max-w-md h-auto mb-2 rounded shadow"
                      onError={async () => {
                        // Essayer de rafraîchir l'URL si elle expire
                        await fetchSignedPhotoUrl(profileId);
                      }}
                    />
                  ) : (
                    <div className="h-32 bg-gray-100 flex items-center justify-center rounded mb-2">
                      <span className="text-gray-500">Chargement de la photo...</span>
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={handleDeletePhoto}
                    className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 mr-2"
                  >
                    Supprimer la photo
                  </button>
                </div>
              ) : (
                <p className="text-sm text-gray-500">Aucune photo actuellement</p>
              )}


              {/* Input pour nouvelle photo */}
              <input
                type="file"
                name="photo"
                onChange={handlePhotoChange}
                accept="image/jpeg,image/jpg,image/png,image/webp"
                className="w-full p-2 border rounded mt-2"
              />
            </div>

            {error && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4"
                role="alert"
              >
                <p className="font-bold">Erreur</p>
                <p>{error}</p>
              </div>
            )}
            <LoadingButton
              isLoading={isSubmitting}
              disabled={loadingGeocoding}
            />
          </form>

          <Footer />

        </>
      )}
    </>
  );
}

export default ProfileForm;
