import React, { useState, useEffect } from 'react';
import { ArrowUpDown, Trash2, Shield, ShieldOff } from "lucide-react";
import axios from '../../axios';

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: 'username',
    direction: 'asc'
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/admin/users');
        setUsers(response.data);
        setError(null);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs:', error);
        setError(error.response?.data?.message || 'Erreur lors de la récupération des utilisateurs');
      }
    };
    fetchUsers();
  }, []);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      const direction = prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (sortConfig.direction === 'asc') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    }
    return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
  });

  const handleAdminToggle = async (userId) => {
    try {
      const response = await axios.put(`/admin/users/${userId}/toggle-admin`);
      if (response.status === 200) {
        setUsers(users.map(user => 
          user._id === userId ? { ...user, isAdmin: !user.isAdmin } : user
        ));
      }
    } catch (error) {
      console.error('Erreur lors du changement de statut admin:', error);
      setError(error.response?.data?.message || 'Erreur lors du changement de statut admin');
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
      try {
        const response = await axios.delete(`/admin/users/${userId}`);
        if (response.status === 200) {
          setUsers(users.filter(user => user._id !== userId));
        }
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
        setError(error.response?.data?.message || 'Erreur lors de la suppression de l\'utilisateur');
      }
    }
  };

  const headers = [
    { key: 'username', label: 'Nom utilisateur' },
    { key: 'email', label: 'Email' },
    { key: 'isAdmin', label: 'Admin' },
    { key: 'hasProfile', label: 'Fiche créée' },
    { key: 'actions', label: 'Actions' }
  ];

  return (
    <div className="w-full mt-8 overflow-x-auto">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          <p>{error}</p>
        </div>
      )}
      
      {users.length === 0 && !error ? (
        <div className="text-center py-4 text-gray-500">
          Chargement des utilisateurs...
        </div>
      ) : (
        <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
          <thead className="bg-gray-50">
            <tr>
              {headers.map(({ key, label }) => (
                <th key={key} className="px-6 py-3 border-b border-gray-200">
                  {key !== 'actions' ? (
                    <button
                      onClick={() => handleSort(key)}
                      className="flex items-center justify-center w-full text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {label}
                      <ArrowUpDown className="ml-2 h-4 w-4" />
                    </button>
                  ) : (
                    <span className="text-sm font-medium text-gray-500">{label}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {sortedUsers.map((user) => (
              <tr key={user._id} className="hover:bg-gray-50">
                <td className="px-6 py-4 text-center text-sm text-gray-900">{user.username}</td>
                <td className="px-6 py-4 text-center text-sm text-gray-900">{user.email}</td>
                <td className="px-6 py-4 text-center text-sm text-gray-900">
                  {user.isAdmin ? 'Oui' : 'Non'}
                </td>
                <td className="px-6 py-4 text-center text-sm text-gray-900">
                  {user.hasProfile ? 'Oui' : 'Non'}
                </td>
                <td className="px-6 py-4">
                  <div className="flex justify-center space-x-2">
                    <button
                      onClick={() => handleAdminToggle(user._id)}
                      className="p-2 text-gray-600 hover:text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
                    >
                      {user.isAdmin ? 
                        <ShieldOff className="h-4 w-4" /> :
                        <Shield className="h-4 w-4" />
                      }
                    </button>
                    <button
                      onClick={() => handleDeleteUser(user._id)}
                      className="p-2 text-gray-600 hover:text-red-600 rounded-lg hover:bg-red-50 transition-colors"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UsersTable;