import React, { useState, useEffect } from 'react';
import axios from '../../axios';  // Assurez-vous que le chemin est correct

const ProfilePopup = ({ profile, onClose }) => {
  const [photoUrl, setPhotoUrl] = useState(null);
  const [userEmail, setUserEmail] = useState(null);


  useEffect(() => {
    const fetchPhotoUrl = async () => {
      if (profile && profile._id && profile.photo) {
        try {
          const token = localStorage.getItem('token');
          const res = await axios.get(`/profiles/${profile._id}/photo-url`, {
            headers: { 'x-auth-token': token },
          });
          setPhotoUrl(res.data.photoUrl);
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'URL de la photo', error);
          setPhotoUrl(null);
        }
      }
    };

    const fetchUserEmail = async () => {
    

      if (profile && profile.user && profile.user._id) {
        try {
          const token = localStorage.getItem('token');
          
          const res = await axios.get(`/users/${profile.user._id}/email`, {
            headers: { 'x-auth-token': token },
          });
          setUserEmail(res.data.email);
        } catch (error) {
          console.error('Erreur détaillée lors de la récupération de l\'email:', error.response || error);
          setUserEmail(null);
        }
      }
    };

    fetchPhotoUrl();
    fetchUserEmail();
  }, [profile]);

  if (!profile) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl w-full max-w-2xl relative overflow-hidden shadow-2xl">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 w-10 h-10 flex items-center justify-center bg-white hover:bg-gray-100 rounded-full transition-colors z-10 shadow-md"
          aria-label="Close popup"
        >
          <span className="text-4xl text-gray-600 leading-none font-light select-none" style={{ marginTop: '-2px' }}>&times;</span>
        </button>

        <div className="max-h-[90vh] overflow-y-auto">
          {/* Profile content */}
          <div className="p-6">
            {/* Photo Section */}
            <div className="mb-6">
              {photoUrl ? (
                <img
                  src={photoUrl}
                  alt={`Famille ${profile.familyName}`}
                  className="w-full h-64 object-cover rounded-lg shadow-md"
                  onError={(e) => {
                    console.error('Erreur de chargement de l\'image');
                    e.target.src = ''; // Vous pouvez mettre une image par défaut ici
                    e.target.classList.add('bg-gray-100');
                  }}
                />
              ) : (
                <div className="w-full h-64 bg-gray-100 rounded-lg shadow-md flex items-center justify-center">
                  <span className="text-gray-500">Aucune photo</span>
                </div>
              )}
            </div>

            {/* Family Details */}
            <div className="space-y-6">
              <div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Famille {profile.familyName}
                </h2>
                <p className="text-gray-700">
                  <strong>Adresse :</strong> {profile.addressNumber} {profile.streetName}
                  <br />59130 Lambersart
                </p>

                <p className="mt-2">
                    <strong>Email :</strong> {userEmail || 'Non disponible'}
                  </p>

              </div>

              {/* Person 1 Details */}
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="font-semibold text-gray-800 mb-2">
                  {profile.person1.name}
                </h3>
                <p className="text-gray-600 mb-2">
                  Profession : {profile.person1.profession}
                </p>
                <p>
                  <a
                    href={`tel:${profile.person1.phone}`}
                    className="text-blue-600 hover:text-blue-800 hover:underline inline-flex items-center"
                  >
                    {profile.person1.phone}
                  </a>
                </p>
              </div>

              {/* Person 2 Details */}
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="font-semibold text-gray-800 mb-2">
                  {profile.person2.name}
                </h3>
                <p className="text-gray-600 mb-2">
                  Profession : {profile.person2.profession}
                </p>
                <p>
                  <a
                    href={`tel:${profile.person2.phone}`}
                    className="text-blue-600 hover:text-blue-800 hover:underline inline-flex items-center"
                  >
                    {profile.person2.phone}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;