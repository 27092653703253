import React, { useState } from 'react';
import axios from '../../axios';

const CopyEmailsButton = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  const copyEmails = async () => {
    try {
      // Récupérer les utilisateurs via l'API
      const response = await axios.get('/admin/users');
      
      // Extraire les emails
      const emails = response.data
        .map(user => user.email)
        .filter(email => email) // Filtrer les emails vides ou undefined
        .join('\n');

      // Copier dans le presse-papier
      await navigator.clipboard.writeText(emails);
      
      // Afficher le message de succès
      setShowSuccess(true);
      setError(null);
      setTimeout(() => setShowSuccess(false), 3000); // Masquer après 3 secondes
      
    } catch (error) {
      console.error('Erreur lors de la copie des emails:', error);
      setError(error.response?.data?.message || 'Erreur lors de la récupération des emails');
      setTimeout(() => setError(null), 3000);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={copyEmails}
        className="bg-yellow-500 text-white px-6 py-3 rounded-md hover:bg-yellow-600 transition-colors"
      >
        Copier les adresses mail
      </button>

      {showSuccess && (
        <div className="absolute top-full mt-2 w-full bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
          Emails copiés dans le presse-papier
        </div>
      )}

      {error && (
        <div className="absolute top-full mt-2 w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}
    </div>
  );
};

export default CopyEmailsButton;