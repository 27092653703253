// client/src/components/footer.js

import React, { useState, useEffect } from 'react';
import RestrictedAccessButton from './routing/restrictedAccessButton';

function Footer() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) {
      console.error("DeferredPrompt is not set");
      return;
    }
  
    try {
      await deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
  
      if (choiceResult.outcome === 'accepted') {
        // L'utilisateur a accepté l'installation
      }
  
      setDeferredPrompt(null);
      setIsInstallable(false);
    } catch (error) {
      console.error("Error during the install prompt:", error);
    }
  };

  return (
    <div className="MainHeader">
      <div className='ContainerFooter'>
        <p align="center">
          Codé à Lambersart par Minucci Tech
        </p>
      </div>
     
      <div id="BoutonInstall" className='ContainerFooter flex justify-center gap-4'>
        {isInstallable && (
          <button 
            onClick={handleInstallClick}
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded transition-colors"
          >
            Installer l'application
          </button>
        )}
      </div>
      
      <div className='ContainerFooter'>
        <RestrictedAccessButton />
      </div>
    </div>
  );
}

export default Footer;