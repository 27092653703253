// client/src/components/routing/restrictedAccessButton.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axios';

const RestrictedAccessButton = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const checkAdminAccess = async () => {
    try {
      const token = localStorage.getItem("token");
      const userStr = localStorage.getItem("user");
      
      if (!token || !userStr) {
        setShowAlert(true);
        return false;
      }

      let user;
      try {
        user = JSON.parse(userStr);
      } catch (e) {
        setShowAlert(true);
        return false;
      }

      if (!user.id) {
        setShowAlert(true);
        return false;
      }

      const res = await axios.get("/admin/check", {
        headers: { 
          "x-auth-token": token,
          "Content-Type": "application/json"
        },
        params: { userId: user.id }
      });

      return res.data?.isAdmin ?? false;

    } catch (error) {
      setShowAlert(true);
      return false;
    }
  };

  const handleRestrictedAccess = async () => {
    const hasAccess = await checkAdminAccess();
    
    if (hasAccess) {
      navigate('/bureau');
    } else {
      setShowAlert(true);
      // L'alerte disparaîtra après 3 secondes
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  return (
    <div className="relative">
      <button 
        onClick={handleRestrictedAccess}
        className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded transition-colors"
      >
        Accès restreint
      </button>

      {showAlert && (
        <div className="fixed top-4 right-4 z-50">
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded shadow-lg">
            <div className="flex items-center">
              <div className="py-1">
                <svg className="w-6 h-6 mr-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z"/>
                </svg>
              </div>
              <div>
                <p className="font-bold">Accès non autorisé</p>
                <p className="text-sm">Vous n'avez pas les droits nécessaires pour accéder à cette section.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestrictedAccessButton;