import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Importer la méthode nommée

const PrivateRoute = ({ children, adminOnly = false }) => {
  const token = localStorage.getItem("token");
  let isAuthenticated = false;
  let isAdmin = false;

  if (token) {
    try {
      const decodedToken = jwtDecode(token); // Décodage du token
      isAuthenticated = true;

      // Vérifiez si l'utilisateur a un rôle admin
      isAdmin = decodedToken.user?.role === "admin";
    } catch (error) {
      console.error("Erreur lors du décodage du token", error);
    }
  }

  // Vérifiez si l'utilisateur est authentifié et autorisé à accéder à la route
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/dashboard" />;
  }

  // Si l'utilisateur est autorisé, affichez les enfants
  return children;
};

export default PrivateRoute;
