import React, { useState, useEffect } from 'react';
import Header from '../header';
import Footer from '../footer';
import axios from '../../axios';

const AccessCodeGenerator = () => {
    const [generatedCode, setGeneratedCode] = useState('');
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [codes, setCodes] = useState([]);

    useEffect(() => {
        fetchCodes();
    }, []);


    //Bouton retour
    const handleGoBack = () => {
        window.history.back();
    };

    const fetchCodes = async () => {
        try {
            const response = await axios.get('/code/list');
            setCodes(response.data);
        } catch (error) {
            setError('Erreur lors de la récupération des codes');
            console.error('Erreur:', error);
        }
    };

    const generateCode = async () => {
        if (!name.trim()) {
            setError('Veuillez entrer un nom');
            return;
        }

        try {
            const response = await axios.post('/code/generate-code', { name });
            setGeneratedCode(response.data.code);
            setError('');
            fetchCodes(); // Rafraîchir la liste des codes
            setName(''); // Réinitialiser le champ nom
        } catch (error) {
            setError(error.response?.data?.message || 'Erreur lors de la génération du code');
            console.error('Erreur:', error);
        }
    };

    const deleteCode = async (codeId) => {
        try {
            await axios.delete(`/code/${codeId}`);
            fetchCodes(); // Rafraîchir la liste après suppression
            setError('');
        } catch (error) {
            setError('Erreur lors de la suppression du code');
            console.error('Erreur:', error);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(generatedCode)
            .then(() => {
                const notification = document.createElement('div');
                notification.className = 'fixed bottom-4 right-4 bg-green-500 text-white px-6 py-3 rounded-md shadow-lg';
                notification.textContent = 'Code copié !';
                document.body.appendChild(notification);

                setTimeout(() => {
                    notification.remove();
                }, 2000);
            })
            .catch(err => {
                setError('Erreur lors de la copie du code');
                console.error('Erreur lors de la copie:', err);
            });
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <Header />

            <div className="max-w-6xl mx-auto mt-8">
                <button
                    onClick={handleGoBack}
                    className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                >
                    Retour
                </button>
            </div>

            <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md">
                <h1 className="text-3xl font-bold mb-6">Générateur de Code d'Accès</h1>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}

                <div className="space-y-6">
                    <div className="space-y-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Nom
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Entrez un nom"
                        />
                    </div>

                    <button
                        onClick={generateCode}
                        className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition-colors w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    >
                        Générer un code d'accès
                    </button>



                    {generatedCode && (
                        <div className="mt-6 space-y-4">
                            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 space-y-4 sm:space-y-0">
                                <input
                                    type="text"
                                    value={generatedCode}
                                    readOnly
                                    className="flex-1 p-3 border rounded-md bg-gray-50"
                                />
                                <button
                                    onClick={copyToClipboard}
                                    className="bg-gray-200 px-4 py-3 rounded-md hover:bg-gray-300 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 sm:w-auto w-full"
                                >
                                    Copier
                                </button>
                            </div>
                        </div>
                    )}

                </div>
            </div>



            <div className="MainCode">
                <h2>Quand le code a été utilisé, vous pouvez le supprimer.</h2>

            </div>




            <div className="max-w-6xl mx-auto mt-8 bg-white rounded-lg shadow-md overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Code</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date de création</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Statut</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Utilisé par</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {codes.map((code) => (
                                <tr key={code._id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{code.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap font-mono">{code.code}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {new Date(code.createdAt).toLocaleDateString('fr-FR')}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${code.used ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                                            }`}>
                                            {code.used ? 'Utilisé' : 'Disponible'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {code.usedBy?.username || 'Non utilisé'}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <button
                                            onClick={() => deleteCode(code._id)}
                                            className="text-red-600 hover:text-red-900 focus:outline-none focus:underline"
                                        >
                                            Supprimer
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default AccessCodeGenerator;